const routes = {
  CONTACT: "/kontakt",
  PROFILE: "/profil",
  REGISTER_MEMBER: "/bli-medlem",
  NEWS_ARCHIVE: "/aktuelt",
  USER: "/user",
  LOGIN: "/login",
  SEARCH: "/sok",
  PREVIEW: "/preview",
  LOG_IN: "/meg",
};

export default routes;
