// @todo: Add these to umbraco settings page instead?
const BOB = {
  PHONE: "+4755547400",
  URL: window.location.origin,
  SOCIAL: {
    FACEBOOK: "https://www.facebook.com/BOBboligbyggelag",
    INSTAGRAM: "https://www.instagram.com/bob_bbl",
    LINKEDIN: "https://www.linkedin.com/company/bobbbl",
    TWITTER: "https://twitter.com/bob_bergen",
  },
};

export default BOB;
